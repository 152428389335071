/* .slick-slide > div {
  padding-left: 24px;
} */

.slick-next,
.slick-prev {
  top: 65%;
  color: #637381 !important;
  width: 18px;
  height: 18px;
  font-style: 20px;
  font-weight: 400;
}

.deal-today-slider .slick-dots {
  display: none !important;
  opacity: 0 !important;
}


@media (max-width: 700px) {
  .deal-today-slider .slick-dots {
    display: block !important;
    opacity: 1 !important;
  }
  .deal-today-slider .slick-next,
  .deal-today-slider .slick-prev {
    opacity: 0 !important;
  }
  .deal-today-slider .slick-dots li button:before {
    font-size: 8px !important;
    color: #fa541c !important;
    width: 18px !important;
    height: 18px !important;
  }
  
  .deal-today-slider .slick-dots li.slick-active div {
    color: #fa541c !important;
    opacity: 1 !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 50% !important;
  }
}


