.fashion-slider .slick-slide > div {
  margin-right: unset;
}

.fashion-slider .slide-background {
  width: 100% !important;
  height: 100% !important;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.fashion-slider .slick-dots {
  width: 30% !important;
  left: -30px !important;
  top: 90% !important;
}

.fashion-slider .slick-dots li button:before {
  font-size: 8px !important;
  color: #fa541c !important;
  opacity: 0.7;
}

.fashion-slider .slick-dots li.slick-active div {
  color: #fa541c !important;
  opacity: 1 !important;
  width: 14px !important;
  height: 8px !important;
  border-radius: 8px;
}

.fashion-slider .slick-next {
  left: 93% !important;
}

.fashion-slider .slick-prev {
  left: 90% !important;
  right: 10% !important;
}

.fashion-slider .slick-next,
.fashion-slider .slick-prev {
  top: 90% !important;
  color: #fa541c !important;
  width: 20px !important;
  height: 20px !important;
}

.fashion-slider .slick-next:before,
.slick-prev:before {
  color: #fa541c !important;
  font-size: 30px !important;
}


@media (max-width: 700px) {
  .fashion-slider .slick-dots  {
    display: block  !important;
    opacity: 1 !important;
  }

  .fashion-slider .slick-dots {
    left: 20px !important;
    opacity: 1 !important;
  }

  .fashion-slider .slick-next {
    left: 90% !important;
    display: block !important;
    opacity: 1 !important;
  }
  
  .fashion-slider .slick-prev {
    left: 80% !important;
    display: block !important;
    opacity: 1 !important;

  }

}
